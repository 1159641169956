/*
 * Copyright © 2017 - 2019 Atilika Inc. All rights reserved.
 */

import React, {memo} from "react";
import {createUseStyles} from "react-jss";
import UI from "./UI";
import Container from "./Container";
import Navigation from "./Navigation";

const useStyles = createUseStyles({
    root: {
        backgroundColor: UI.colors.slate.toCSS(),
        height: "7.25rem",
        position: "relative"
    }
});

function PlainHeader() {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Container>
                <Navigation />
            </Container>
        </div>
    );
}

export default memo(PlainHeader);
