/*
 * Copyright © 2017 - 2019 Atilika Inc. All rights reserved.
 */

import React, {memo, ReactNode} from "react";
import {createUseStyles} from "react-jss";
import {H2} from "./typography/Headings";
import {Lead} from "./typography/Text";
import Button from "./Button";
import Container from "./Container";
import UI from "./UI";
import {StaticImage} from "gatsby-plugin-image";

const useStyles = createUseStyles({
    root: {
        position: "relative"
    },
    wrapper: {
        padding: "7rem 0",
        [UI.media.only("xs")]: {
            padding: "4rem 0"
        }
    },
    mask: {
        backgroundImage: `linear-gradient(
            359deg,
            ${UI.colors.mediumLightGrey.alpha(0.94).toCSS()},
            rgba(194, 194, 194, .86),
            ${UI.colors.veryLightGrey.toCSS()} 0%,
            ${UI.colors.translucentWhite.toCSS()}
        )`,
        height: "100%",
        mixBlendMode: "multiply",
        position: "absolute",
        top: 0,
        width: "100%",
        zIndex: 2
    },
    content: {
        position: "relative",
        color: UI.colors.white.toCSS(),
        margin: "0 auto 0 0",
        width: "100%",
        zIndex: 3,
        [UI.media.only("md")]: {
            width: "75%"
        },
        [UI.media.gte("lg")]: {
            width: "50%"
        }
    },
    backgroundImage: {
        height: "100%",
        objectFit: "cover",
        left: 0,
        top: 0,
        position: "absolute",
        opacity: 0.57
    }
});

interface ContactUsProps {
    title: string;
    children: ReactNode;
    email?: string;
}

function ContactUs(props: ContactUsProps) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <StaticImage
                className={classes.backgroundImage}
                src="../assets/raw-photos/laptop-on-coffee-table.jpg"
                alt=""
                layout="constrained"
                width={2500}
            />
            <div className={classes.mask} />
            <div className={classes.wrapper}>
                <Container>
                    <div className={classes.content}>
                        <H2>{props.title}</H2>
                        <Lead size="large">{props.children}</Lead>
                        <Button href={`mailto:${props.email || "hello@atilika.com"}`}>
                            {props.email || "hello@atilika.com"}
                        </Button>
                    </div>
                </Container>
            </div>
        </div>
    );
}

export default memo(ContactUs);
