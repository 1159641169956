/*
 * Copyright © 2017 - 2019 Atilika Inc. All rights reserved.
 */

import React, {memo, ReactNode} from "react";
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles({
    root: {
        "& p, li": {
            fontSize: "1.125rem",
            lineHeight: 1.5
        },
        "& p": {
            marginBottom: "1.5rem"
        },
        "h4 ": {
            marginBottom: ".25rem"
        }
    }
});

interface CareersPositionProps {
    children: ReactNode;
}

function CareersPosition(props: CareersPositionProps) {
    const classes = useStyles();
    return <div className={classes.root}>{props.children}</div>;
}

export default memo(CareersPosition);
