/*
 * Copyright © 2017 - 2019 Atilika Inc. All rights reserved.
 */

import React, {memo} from "react";
import CareersPosition from "../../careers/CareersPosition";
import ContactUs from "../../common/ContactUs";
import Footer from "../../common/Footer";
import Intro from "../../common/Intro";
import Page from "../../common/Page";
import Link from "../../common/Link";
import Meta from "../../common/Meta";
import PlainHeader from "../../common/PlainHeader";
import Section from "../../common/Section";
import {H4} from "../../common/typography/Headings";
import {LI, UL} from "../../common/typography/List";

const CareersEn = () => (
    <Page lang="en" title="Careers" path="/en/careers/">
        <Meta name="description">
            Atilika is a natural language processing and AI company with offices in Tokyo and Oslo.
            Leading businesses worldwide rely on our technology products and deep learning
            solutions.
        </Meta>
        <PlainHeader />

        <Intro title="Careers">
            <p>
                If you enjoy working with the latest in technology and applying your skills to
                high-profile customer solutions, Atilika might be a good place for you.
            </p>
        </Intro>

        <Section title="Reasons to join" grey>
            <UL>
                <LI>
                    We offer employees a relaxed working environment, flexible hours, generous
                    vacation plans.
                </LI>

                <LI>Atilika offers company sponsored career and foreign language training.</LI>

                <LI>
                    All employees have a voice in the direction of the company and the kind of tasks
                    they work on.
                </LI>

                <LI>
                    Learn about the latest advances in data science and natural language processing
                    as part of you work.
                </LI>

                <LI>Work with and on open source software.</LI>

                <LI>
                    Work on projects for high-profile companies that are industry leaders in
                    telecommunications, online retailing, and publishing.
                </LI>
            </UL>
        </Section>

        <Section title="Job openings">
            <p>We have the following positions open:</p>
        </Section>

        <Section title="Intern Software Engineer" grey>
            <CareersPosition>
                <p>
                    We are looking for a passionate programmer to join our engineering team in
                    Tokyo, Japan or Oslo, Norway as an Intern Software Engineer.
                </p>

                <p>Interns will get to participate in many activities, such as:</p>

                <UL>
                    <LI>Helping build prototypes for new technology based on academic research.</LI>

                    <LI>Develop new functionality for our own products and technology.</LI>

                    <LI>Contributing to open source projects.</LI>

                    <LI>Contributing to customer consulting projects.</LI>
                </UL>

                <H4>Internship period</H4>

                <p>
                    We are flexible with regards to the internship period and we can accommodate
                    internships for 2 to 12 months. The start date and duration will be mutually
                    agreed on with the intern. We are open to both part-time and full-time
                    internships.
                </p>

                <H4>Salary</H4>

                <p>
                    We are offering an hourly salary of JPY 2,250. We also cover commuting expenses
                    to our office.
                </p>

                <H4>Desired qualifications</H4>

                <UL>
                    <LI>
                        Good overall programming skills with at least one of the following
                        languages: Java, Python, or JavaScript.
                    </LI>
                    <LI>
                        Experience developing web applications, including familiarity with HTML,
                        CSS, and popular tools like React or jQuery is a plus.
                    </LI>
                    <LI>
                        Prior experience in information retrieval or computational linguistics is a
                        big plus.
                    </LI>
                    <LI>
                        Prior experience with popular tools such as Lucene, Solr, Elasticsearch or
                        PostgreSQL is a plus.
                    </LI>
                    <LI>Some level of proficiency in Japanese or keen interest is a big plus.</LI>
                    <LI>
                        A Bachelor or Masters degree in computer science or other relevant field.
                    </LI>
                </UL>

                <H4>Visas</H4>

                <p>
                    For applicants to our position in Tokyo, a visa will typically be required if
                    you are non-resident of Japan. We can help on visa matters, but we can not
                    promise to sponsor visas in all cases.
                </p>

                <H4>Applying</H4>

                <p>
                    To apply, please send your resume and a short introduction to{" "}
                    <Link href="mailto:careers@atilika.com">careers@atilika.com</Link>. Please also
                    indicate whether you would like to apply to the position in Tokyo or in Oslo. We
                    look forwards to hearing from you!
                </p>
            </CareersPosition>
        </Section>

        <ContactUs title="Get in touch" email="careers@atilika.com">
            <p>
                We are always looking for talented engineers. Please do not hesitate to contact us
                for a friendly talk about opportunities.
            </p>
        </ContactUs>

        <Footer lang="en" />
    </Page>
);

export default memo(CareersEn);
