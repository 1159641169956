/*
 * Copyright © 2017 - 2019 Atilika Inc. All rights reserved.
 */

import React, {ReactNode} from "react";
import {IoIosArrowForward as RightChevronIcon} from "react-icons/io";
import {createUseStyles} from "react-jss";

const useListStyles = createUseStyles({
    root: {
        listStyle: "none",
        paddingLeft: 0,
        "&:last-child": {
            marginBottom: 0
        }
    }
});

interface ListProps {
    children: ReactNode;
}

export function UL(props: ListProps) {
    const classes = useListStyles();
    return <ul className={classes.root}>{props.children}</ul>;
}

const useItemStyles = createUseStyles({
    root: {
        display: "flex",
        flexDirection: "row",
        marginBottom: "1rem",
        "&:last-child": {
            marginBottom: 0
        }
    },
    icon: {
        marginRight: ".5rem",
        display: "block",
        "& svg": {
            marginTop: ".250rem"
        }
    }
});

interface ListItemProps {
    children: string | string[];
}

export function LI(props: ListItemProps) {
    const classes = useItemStyles();
    return (
        <li className={classes.root}>
            <span className={classes.icon}>
                <RightChevronIcon />
            </span>
            {props.children}
        </li>
    );
}
